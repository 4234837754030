import React, { useState, useEffect } from 'react';
import crlogo from './img/cr_logo.png';

const ManifestRetriever = ({ fileName, filePath }) => {
    const [manifest, setManifest] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [showManifest, setShowManifest] = useState(false);

    useEffect(() => {
        const fetchManifest = async () => {
            setIsLoading(true);
            setError('');
            setManifest(null);

            try {
                console.log(`Fetching manifest for file: ${filePath}`);
                const fileBlob = await fetch(filePath).then(res => res.blob());
                const fileObj = new File([fileBlob], fileName, { type: 'image/jpeg' }); // Adjust the MIME type if necessary

                const formData = new FormData();
                formData.append('file', fileObj);

                const response = await fetch('https://paybots-claim-engine.azurewebsites.net/api/manifest', {
                    method: 'POST',
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch manifest. Status: ${response.status}`);
                }
                const data = await response.json();
                setManifest(data);
            } catch (err) {
                console.error('Error fetching manifest:', err);
                setError(`Error: ${err.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchManifest();
    }, [filePath, fileName]);

    const handleToggleManifest = () => {
        setShowManifest(!showManifest);
    };

    return (
        <div className="inspectFile">
            {isLoading && <p>Getting Content Credentials...</p>}
            <div className="clog" onClick={handleToggleManifest}>

                <img src={crlogo} className="crlogo" title="Show/Hide Content Credentials" alt="Show/Hide Content Credentials" />
            </div>
            {showManifest && (
                <div className="manifest">
                    {error && <p className="error">{error}</p>}
                    {manifest && <pre>{JSON.stringify(manifest, null, 2)}</pre>}
                    <div className="claimedat">
                        Claim your files at <a href="https://file.baby">File Baby</a> using <a href="https://c2pa.org">C2PA</a>-based <a href="https://contentcredentials.org/verify">Content Credentials</a>.
                    </div></div>


                )}
        </div>
    );
};

export default ManifestRetriever;
