import React from "react";

const VideoSection5 = () => (
    <div className="video">
<h2>On the MIC with MIKE RVA: The Daily Business Show Taking RVA by Storm</h2>
            <h3>ESPN 106.1 Richmond</h3>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/V4y47jxUFi0?si=vQTw0lcQH5LAWcTU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>    </div>
);

export default VideoSection5;
