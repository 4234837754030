//blogPosts/blogPosts.js
import image1 from '../kbc_logo_1200.png';
import image2 from '../Shantanu_Narayen.png';
import image3 from '../img/content_provenance_blog.png'
import image4 from '../AI_on_Pi_Day.png';
import image5 from '../img/File_Baby-Orson_Weems-BAANG-Karen_Kilroy-at_Love_More_Records.png';


const blogPosts = [
    {
        id: 5,
        urlkey:'nwabusinessjournal',
        title: 'File Baby Featured in NW Arkansas Business Journal',
        author: 'Karen Kilroy',
        date: 'May 28, 2024',
        image: image5,
        alt: 'File Baby featured in NW Arkansas Business Journal',
        description: 'File Baby\'s Karen Kilroy and Orson Weems join Love More Records artist BAANG while he claims his files using File Baby.' ,
        component: () => import('./BlogPost5'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 4,
        urlkey:'piday24',
        title: 'File Baby Hosts AIDA 24 Hours of Pi Day',
        author: 'Karen Kilroy',
        date: 'May 17, 2024',
        image: image4,
        alt: '24 hours of AI on Pi Day, AIDA User Group, Karen Kilroy and Orson Weems',
        description: 'File Baby\'s Karen Kilroy and Orson Weems join Jean-Georges Perrin and other AIDA User Group members as hosts of the 24 Hours of Pi Day, North America Track.' ,
        component: () => import('./BlogPost4'), // Assuming BlogPost1.js returns a default React component
    },

    {
        id: 3,
        urlkey:'deepfakes',
        title: 'Content Provenance can Identify Deep Fakes',
        author: 'Alvin Singh',
        date: 'May 5, 2024',
        image: image3,
        alt: 'AI generated disc and person',
        description: 'Deep fakes, especially in the entertainment industry, are everywhere. Content provenance can help to avoid deep fakes of musicians and artists.',
        component: () => import('./BlogPost3'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 2,
        urlkey:'shantanu_narayen',
        title: 'Interview with Adobe CEO Shantanu Narayen about GenAI',
        author: 'Scott Harris',
        date: 'May 1, 2024',
        image: image2,
        alt: 'Adobe CEO Shantanu Narayen',
        description: 'Adobe CEO Shantanu Narayen talks about GenAI and content credentials in this new era of generative AI and tackling misinformation.',
        component: () => import('./BlogPost2'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 1,
        urlkey:'filebaby',
        title: 'File Baby Partners with Kilroy Blockchain',
        author: 'Karen Kilroy',
        date: 'February 28, 2024',
        image: image1,
        alt: 'That\'s My File, BABY!',
        description: 'File Baby is partnering with Kilroy Blockchain to offer enterprise-level blockchain security to its Content Authenticity and Provenance Platform.',
        component: () => import('./BlogPost1'), // Assuming BlogPost1.js returns a default React component
    },

];

export default blogPosts;