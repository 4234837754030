// VideoPage.js
import React from 'react';
import SubscribeBtnSection from "./SubscribeBtnSection";
import VideoSection from "./VideoSection";
import VideoSection2 from "./VideoSection2";
import VideoSection4 from "./VideoSection4";
import VideoSection5 from "./VideoSection5";
import VideoSection7 from "./VideoSection7";

const VideoPage = () => (
    <div className="content">
        <h1>Videos</h1>

        <div>Watch these videos to learn more about how File Baby can help you protect your original content.</div>
            <VideoSection5 />
        <h2>The Depeopling of the White Collar Workforce</h2>
        <VideoSection7 />
        <h2>Doing Business in Bentonville</h2>
        <VideoSection />
            <h2>Hyperledger Media and Entertainment Special Interest Group</h2>
        <VideoSection2 />
            <h2>BAANG of Love More Records Claims Song on File Baby</h2>
            <VideoSection4 />

        <SubscribeBtnSection />
    </div>
);


export default VideoPage;