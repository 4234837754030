import React from 'react';
import fileBabyLogo from './logo_myfilebaby.png';
import ManifestRetriever from './ManifestRetriever';

const FileBabyLargeLogoSection = () => (
    <div className="file-baby-logo-section">
        <div className="image-container filebabylarge">
            <img src={fileBabyLogo} alt="File Baby Large Logo" />
            <ManifestRetriever fileName="logo_myfilebaby.png" filePath={fileBabyLogo} />
        </div>
    </div>
);

export default FileBabyLargeLogoSection;
