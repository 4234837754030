// HeroSection.js
import React from 'react';


const FreeFileBabyMediaPlayerPage = () => (
    <div className={"content"}>
        <h1>Free File Baby Media Player</h1>
        <div className={"normal-text"}>
          <p>Try File Baby Media Player... it is completely free to use!</p>
          <p>File Baby Media Player lets you enjoy your media while you peek inside your files–images, audio and video–to see if they contain content credentials.</p>
            <p><a href={"https://play.file.baby"}>TOTALLY FREE, NO DOWNLOAD REQUIRED: File Baby Media Player at https://play.file.baby</a></p>
        </div>
    </div>

);
export default FreeFileBabyMediaPlayerPage;