import React, { useState } from 'react';
import FileBabyTop from "./file_baby_logo_top.png";
import Chatbot from "./Chatbot";

const Footer = () => {
    const [, setFilterCriteria] = useState({});

    // Function to handle filter criteria changes
    const handleFilterCriteriaChange = (newCriteria) => {
        setFilterCriteria(newCriteria);
    };

    return (
        <section className="footer">
            <div className="footer">
                <div>
                    <Chatbot setFilterCriteria={handleFilterCriteriaChange} />
                    <img className="filebabyfooter" src={FileBabyTop} alt="File Baby logo" />
                </div>
                <p>
                    &copy;Copyright 2024, <a href="https://my.file.baby">File Baby</a>, All Rights Reserved
                </p>
            </div>
        </section>
    );
};

export default Footer;
