import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import FileBabyTop from "./file_baby_logo_top.png";

const Navbar = () => {
    const [menuActive, setMenuActive] = useState(false);
    const [dropdownActive, setDropdownActive] = useState(false);
    let navigate = useNavigate();

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    const toggleDropdown = () => {
        setDropdownActive(!dropdownActive);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setMenuActive(false);
    };

    return (
        <section className="navbar">
            <div className="menu-icon" onClick={toggleMenu}>
                {/* Hamburger Icon */}
                {menuActive ? (
                    <span>X</span> /* Style this div as an 'X' icon */
                ) : (
                    <div>
                        <div></div>
                        <div></div>

                    </div> /* Hamburger lines */
                )}
            </div>
            <div className={`menu ${menuActive ? 'active' : ''}`}>
                <ul className={`nav-list ${menuActive ? 'active' : ''}`}>
                    <li><a href="/" onClick={() => handleNavigation('/')}>
                        <img src={FileBabyTop} alt={"File Baby wording"}/>
                    </a></li>
                    <li className={"nav-item"}><Link to="how-file-baby-works" onClick={() => handleNavigation('/')}>How it Works</Link></li>

                    {/* ... other menu items */}


                    <li className="nav-item dropdown">
                        <button className="dropbtn" onClick={toggleDropdown}>
                            Resources
                            {/* Include an icon or character for the twisty */}
                            <span className={`twisty ${dropdownActive ? 'open' : 'closed'}`}>▼</span>
                        </button>

                        {dropdownActive && (
                            <div className="dropdown-content">
                                    <Link to="about-file-baby" onClick={() => handleNavigation('/')}>About</Link>
                               <Link to="blog" onClick={() => handleNavigation('/')}>Blog</Link>
                                <Link to="file-baby-player" onClick={() => handleNavigation('/file-baby-player')}>File Baby Media Player</Link>

                            </div>
                        )}
                    </li>

                    <li className="nav-item dropdown">
                        <button className="dropbtn" onClick={toggleDropdown}>
                            NYX NoCode
                            {/* Include an icon or character for the twisty */}
                            <span className={`twisty ${dropdownActive ? 'open' : 'closed'}`}>▼</span>
                        </button>

                        {dropdownActive && (
                            <div className="dropdown-content">
                                <Link to="NYX-Nocode" onClick={() => handleNavigation('/')}>NYX NoCode</Link>
                                <Link to="NYX-Catalog" onClick={() => handleNavigation('/file-baby-player')}>NYX Examples</Link>

                            </div>
                        )}
                    </li>
                    <li className={"nav-item"}><a href={"https://subscribe.file.baby"} onClick={() => handleNavigation('/')}>Subscribe</a></li>

                    <li className={"nav-item"}><a href={"https://my.file.baby"} onClick={() => handleNavigation('/')}>Go to File Baby</a></li>
                </ul>
            </div>
        </section>
    );
};

export default Navbar;
