//./blog_posts/BlogList.js
import blogPosts from './blogPosts';
import {Link} from "react-router-dom";
import React from "react";

const BlogList = () => (
    <div className={"content"}>
        <h1>Blog</h1>
        {blogPosts.map(post => (
            <div className={"bloglist"} key={post.id} >
                <Link to={`/blog/post/${post.urlkey}`}><img className="herosmall filebaby" src={post.image} alt={"hero"} /></Link>
                <h2 className={"blog"}><Link to={`/blog/post/${post.urlkey}`}>{post.title}</Link></h2>
                <p>By {post.author} on {post.date}</p>
                <p>{post.description} <Link to={`/blog/post/${post.urlkey}`}>read more</Link></p>
            </div>
        ))}
        <div className={"parallax"}></div>

    </div>
);
export default BlogList;