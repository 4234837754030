import React from "react";

const VideoSection7 = () => (
    <div className="video">
        <h2>Karen Kilroy & Orson Weems on AIDA 24 Hours of Pi Day</h2>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/BXiJO-h51CY?si=2Fs28241lOh78Pvf&amp;start=7060" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
    </div>
);
export default VideoSection7;

