// HeroSection.js
import React from 'react';
import SubscribeBtnSection from "./SubscribeBtnSection";

const MerchPage = () => (
    <div className="content">
        <h1>Merch</h1>
        <p>Available by popular demand, you can now tell the world "That's My File, Baby!" by rocking File Baby merch! We have File Baby Blue t-shirts, hats, PJs, dogtags, and more.</p>
        <SubscribeBtnSection />
    </div>
);


export default MerchPage;