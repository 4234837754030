// App.js
import React from 'react';
import HomePage from "./HomePage";
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import PrivacyPage from "./PrivacyPage";
import TermsOfUsePage from "./TermsOfUsePage";
import AboutPage from "./AboutPage"
import FAQPage from "./FAQPage"
import BlogPage from "./BlogPage"
import Footer from "./Footer"
import HowItWorksPage from "./HowItWorksPage";
import Navbar from "./Navbar"
import SubscriptionsPage from "./SubscriptionsPage";
import PressPage from "./PressPage";
import MerchPage from "./MerchPage";
import VideoPage from "./VideoPage"
import ContactUsPage from "./ContactUsPage";
import NotFoundPage from "./NotFoundPage";
import DynamicBlogPost from "./DynamicBlogPost";
import FileBabyPlayer from "./FreeFileBabyMediaPlayerPage"
import NYXNoCode from "./NYXNoCode";
import NyxCatalog from './NyxCatalog'; // Import the NyxCatalog component


function App() {
    return (
       <Router>
           <div className={"App"}>
               <Navbar />
               <Routes>
                   <Route path={"/"} element={<HomePage />} />
                   <Route path={"about-file-baby"} element={<AboutPage />} />
                   <Route path={"how-file-baby-works"} element={<HowItWorksPage/>} />
                   <Route path={"subscriptions"} element={<SubscriptionsPage />} />
                   <Route path={"press"} element={<PressPage />} />
                   <Route path={"merch"} element={<MerchPage />} />
                   <Route path={"privacy-policy"} element={<PrivacyPage />} />
                   <Route path={"terms-of-service"} element={<TermsOfUsePage />} />
                   <Route path={"frequently-asked-questions"} element={<FAQPage />} />
                   <Route path={"videos"} element={<VideoPage />} />
                   <Route path={"contact-us"} element={<ContactUsPage />} />
                   <Route path={"404.html"} element={<NotFoundPage />} />
                   <Route path="/blog/*" element={<BlogPage />} />
                   <Route path="/blog/:id" element={<DynamicBlogPost />} />
                   <Route path="/blog/post/:urlkey" element={<DynamicBlogPost />} />
                   <Route path={"file-baby-player"} element={<FileBabyPlayer />} />
                   <Route path={"nyx-nocode"} element={<NYXNoCode />} />
                   <Route path={"nyx-catalog"} element={<NyxCatalog />} />
               </Routes>
           </div>
           <Footer />
       </Router>
    );
}

export default App;
